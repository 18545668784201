<template>
    <span :style="styleObject" :class="classes" class="badge">
        {{ prePendText + text + postPendText }}
    </span>
</template>

<script lang="js">

export default {
    name: 'AlertStatus',
    data: () => {
        return {
            classes: {}
        }
    },
    props: {
        prePendText: {type: String, default: function() {
            return ''
        }},
        text: {type: String, required: true},
        postPendText: {type: String, default: function() {
            return ''
        }},
        classObject: {type: Object, default: function() {
            return {}
        }},
        styleObject: {type: Object, default: function() {
            return {}
        }}
    },
    beforeMount() {
        this.classes = this.classObject

        if (this.text == 'Active') {
            this.classes['bg-success'] = true
        } else {
            this.classes['bg-danger'] = true
        }

    }
}
</script>
<style scoped lang="scss"></style>

// @ts-nocheck

/*
Stores the auth data and token returned from Azure B2C
*/
import axios from 'axios'

const ProfileModule = {
    state: {
        contact: null
    },
    getters: {
        profileRoles: state => (state.contact ? state.contact.roles : [])
    },
    mutations: {
        setContact(state, contact) {
            state.contact = contact
        },
        clearContactData(state) {
            state.contact = null
        }
    },
    actions: {
        async getRoles({ state, commit }) {
            if (!state.contact) {
                const response = await axios.get('/v1/profile')
                await commit('setContact', response.data.data[0])
            }
        },
        async saveContactRecord({ commit }, contact) {
            //Save to 365
            const profileResponse = await axios.post('/v1/profile', contact)

            //update Contact state
            const response = await axios.get('/v1/profile')
            await commit('setContact', response.data.data[0])

            return profileResponse.data.data[0]
        }
    }
}

export default ProfileModule

<template>
    <div class="row">
        <div class="card">
            <div class="card-body">
                <Breadcrumb id="mainBreadCrumb" :home="home" :model="items" />
            </div>
        </div>
    </div>
</template>

<script lang="js">
import Breadcrumb from 'primevue/breadcrumb'

export default {
    name: 'BreadCrumbPanel',
    components: {Breadcrumb},
    data: () => {
        return {
            home: {icon: 'pi pi-home', to: '/'},
        }
    },
    props: {
            items: Array,
    }
}
</script>
<style scoped lang="scss">
#mainBreadCrumb {
    background-color: white;
    padding-top: 20px;
    font-size: 120%;
}
</style>

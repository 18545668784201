<template>
    <div class="splash" :class="{ active: isLoading, inactive: !isLoading }">
        <div class="splash-icon"></div>
    </div>
    <div class="wrapper">
        <side-bar></side-bar>

        <div class="main">
            <nav-bar></nav-bar>
            <main class="content">
                <div class="container-fluid">
                    <div class="header">
                        <h1 class="header-title">
                            Members Area
                        </h1>
                        <p class="header-subtitle">
                            Some catchy text
                        </p>
                    </div>

                    <router-view></router-view>
                </div>
            </main>
            <Footer></Footer>
        </div>
    </div>

    <svg width="0" height="0" style="position:absolute">
        <defs>
            <symbol viewBox="0 0 512 512" id="ion-ios-pulse-strong">
                <path
                    d="M448 273.001c-21.27 0-39.296 13.999-45.596 32.999h-38.857l-28.361-85.417a15.999 15.999 0 0 0-15.183-10.956c-.112 0-.224 0-.335.004a15.997 15.997 0 0 0-15.049 11.588l-44.484 155.262-52.353-314.108C206.535 54.893 200.333 48 192 48s-13.693 5.776-15.525 13.135L115.496 306H16v31.999h112c7.348 0 13.75-5.003 15.525-12.134l45.368-182.177 51.324 307.94c1.229 7.377 7.397 11.92 14.864 12.344.308.018.614.028.919.028 7.097 0 13.406-3.701 15.381-10.594l49.744-173.617 15.689 47.252A16.001 16.001 0 0 0 352 337.999h51.108C409.973 355.999 427.477 369 448 369c26.511 0 48-22.492 48-49 0-26.509-21.489-46.999-48-46.999z"
                ></path>
            </symbol>
        </defs>
    </svg>
</template>

<script>
//import Vue from 'vue'

import SideBar from '@/components/layouts/SideBar'
import NavBar from '@/components/layouts/NavBar'
import Footer from '@/components/layouts/Footer'

//import Header from '@/components/layouts/Header'

//import ContentWrapper from '@/components/layouts/ContentWrapper'

//import ContentHeading from '@/components/layouts/ContentHeading.vue'

//vue.component('ContentWrapper', ContentWrapper)

export default {
    name: 'Layout',
    data: () => {
        return {
            isLoading: true
        }
    },
    mounted() {
        this.isLoading = false
    },
    components: {
        SideBar,
        NavBar,
        Footer
        //ContentWrapper,
        //Header,
    }
}
</script>

<style lang="scss"></style>

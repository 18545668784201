// @ts-nocheck

/*
Stores the entities lookup data
*/
import axios from 'axios'

const EntitiesModule = {
    state: {
        roles: []
    },
    getters: {
        //profileRoles: state => (state.contact ? state.contact.roles : [])
    },
    mutations: {
        addRoleNames(state, data) {
            state.roles.push({ id: data.id, value: data.selectField })
        }
    },
    actions: {
        async retrieveRoleNames({ state, commit }) {
            //check if its in the state array already
            if (state.roles.length > 0) return state.roles

            //if not found, get and add to state
            const response = await axios.get(
                '/v1/entities/lookups/new_contactroletypes'
            )
            response.data.data.forEach(element => {
                commit('addRoleNames', element)
            })

            return state.roles
        }
    }
}

export default EntitiesModule

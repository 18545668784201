<template>
    <footer class="footer">
        <div class="container-fluid">
            <div class="row text-muted">
                <div class="col-8 text-start">
                    <ul class="list-inline">
                        <li class="list-inline-item">
                            <a class="text-muted" href="#">Privacy</a>
                        </li>
                        <li class="list-inline-item">
                            <a class="text-muted" href="#">Terms of Service</a>
                        </li>
                    </ul>
                </div>
                <div class="col-4 text-end">
                    <p class="mb-0">
                        &copy; 2021 -
                        <a href="/" class="text-muted">Certass Ltd</a>
                    </p>
                </div>
            </div>
        </div>
    </footer>
</template>

<style scoped lang="scss"></style>

<template>
    <!-- NAVBAR START-->
    <nav class="navbar navbar-expand navbar-theme">
        <a class="sidebar-toggle d-flex me-2">
            <i class="hamburger align-self-center"></i>
        </a>

        <div class="navbar-collapse collapse" v-if="isAuthenticated">
            <ul class="navbar-nav ms-auto">
                <li class="nav-item dropdown ms-lg-2">
                    <a
                        class="nav-link dropdown-toggle position-relative"
                        href="#"
                        id="alertsDropdown"
                        data-bs-toggle="dropdown"
                    >
                        <i class="align-middle pi pi-bell"></i>
                        <span class="indicator"></span>
                    </a>
                    <div
                        class="dropdown-menu dropdown-menu-lg dropdown-menu-end py-0"
                        aria-labelledby="alertsDropdown"
                    >
                        <div class="dropdown-menu-header">
                            2 New Notifications
                        </div>
                        <div class="list-group">
                            <a href="#" class="list-group-item">
                                <div class="row g-0 align-items-center">
                                    <div class="col-2">
                                        <i
                                            class="ms-1 text-danger fas fa-fw fa-bell"
                                        ></i>
                                    </div>
                                    <div class="col-10">
                                        <div class="text-dark">
                                            Update completed
                                        </div>
                                        <div class="text-muted small mt-1">
                                            Restart server 12 to complete the
                                            update.
                                        </div>
                                        <div class="text-muted small mt-1">
                                            2h ago
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a href="#" class="list-group-item">
                                <div class="row g-0 align-items-center">
                                    <div class="col-2">
                                        <i
                                            class="ms-1 text-warning fas fa-fw fa-envelope-open"
                                        ></i>
                                    </div>
                                    <div class="col-10">
                                        <div class="text-dark">
                                            Lorem ipsum
                                        </div>
                                        <div class="text-muted small mt-1">
                                            Aliquam ex eros, imperdiet vulputate
                                            hendrerit et.
                                        </div>
                                        <div class="text-muted small mt-1">
                                            6h ago
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="dropdown-menu-footer">
                            <a href="#" class="text-muted"
                                >Show all notifications</a
                            >
                        </div>
                    </div>
                </li>
                <li class="nav-item dropdown ms-lg-2">
                    <a
                        class="nav-link dropdown-toggle position-relative"
                        href="#"
                        id="userDropdown"
                        data-bs-toggle="dropdown"
                    >
                        <i class="align-middle pi pi-cog"></i>
                    </a>
                    <div
                        class="dropdown-menu dropdown-menu-end"
                        aria-labelledby="userDropdown"
                    >
                        <a class="dropdown-item" href="#"
                            ><i class="align-middle me-1 fas fa-fw fa-user"></i>
                            View Profile</a
                        >
                        <a class="dropdown-item" href="#"
                            ><i
                                class="align-middle me-1 fas fa-fw fa-comments"
                            ></i>
                            Contacts</a
                        >
                        <a class="dropdown-item" href="#"
                            ><i
                                class="align-middle me-1 fas fa-fw fa-chart-pie"
                            ></i>
                            Analytics</a
                        >
                        <a class="dropdown-item" href="#"
                            ><i class="align-middle me-1 fas fa-fw fa-cogs"></i>
                            Settings</a
                        >
                        <div class="dropdown-divider"></div>
                        <router-link
                            to="/login"
                            class="dropdown-item"
                            @click="logOut"
                            ><i class="align-middle me-1 pi pi-sign-out"></i>
                            Sign out
                        </router-link>
                    </div>
                </li>
            </ul>
        </div>

        <div class="navbar-collapse collapse" v-if="!isAuthenticated">
            <ul class="navbar-nav ms-auto">
                <li class="nav-item dropdown ms-lg-2">
                    <router-link
                        to="/login"
                        class="nav-link dropdown-toggle position-relative"
                        id="alertsDropdown"
                    >
                        <i class="align-middle pi pi-sign-in"></i>
                    </router-link>
                </li>
            </ul>
        </div>
    </nav>
    <!-- NAVBAR END-->
</template>

<script lang="js">

import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'NavBar',
    data: () => {
        return {}
    },
    computed: {
        ...mapGetters(['isAuthenticated'])
    },
    methods: {
        ...mapActions(['logOut'])
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>

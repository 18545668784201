// @ts-nocheck

import { createApp } from 'vue'
import App from '@/App.vue'
//import Emitter from 'tiny-emitter'
import router from '@/router'
import store from '@/store'
import axios from 'axios'

//Prime Vue bits
import PrimeVue from 'primevue/config'
import 'primeicons/primeicons.css'
import '@/css/bootstrap4-light-blue/theme.css'

//Prime Vue components that get used frequently
import Button from 'primevue/button'
import Message from 'primevue/message'
import Card from 'primevue/card'
import Badge from 'primevue/badge'
import Panel from 'primevue/panel'
import Skeleton from 'primevue/skeleton'
import InputText from 'primevue/inputtext'

import BreadCrumbPanel from '@/components/helpers/BreadCrumbPanel'
import AlertStatus from '@/components/helpers/AlertStatus'
import FieldRequiredNotification from '@/components/helpers/FieldRequiredNotification'

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_URL

axios.interceptors.response.use(
    response => {
        return response
    },
    error => {
        const originalRequest = error.config
        if (error.response.status === 403 && !originalRequest._retry) {
            originalRequest._retry = true
            store.dispatch('logOut')
            return router.push('/login')
        }

        throw error
    }
)

const app = createApp(App)

//app.component('account-panel', AccountPanel)

//console.log(store.state.auth.account)

//Create emitter and attach some default events
/*
const emitter = new Emitter()

emitter.on('login', account => {
    store.commit('setAccount', account)
})

emitter.on('logout', () => {
    store.commit('setAccount', {})
})
*/
app.use(store)
    .use(router)
    .use(PrimeVue, { ripple: true })
    .mount('#app')

//Global Components
app.component('Button', Button)
app.component('Message', Message)
app.component('Card', Card)
app.component('Badge', Badge)
app.component('Panel', Panel)
app.component('Skeleton', Skeleton)
app.component('InputText', InputText)

app.component('field-required-notification', FieldRequiredNotification)
app.component('bread-crumb-panel', BreadCrumbPanel)
app.component('alert-status', AlertStatus)

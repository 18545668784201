<template>
    <div class="sidebar-content">
        <div class="sidebar-user">
            <div class="fw-bold">
                <small class="text-muted">logged in as</small><br />
                Certass Applicant
            </div>
        </div>

        <ul class="sidebar-nav">
            <li class="sidebar-header">
                What do you want to do?
            </li>
            <li class="sidebar-item">
                <router-link to="/" class="sidebar-link">
                    <i class="align-middle me-2 pi pi-home "></i>
                    <span class="align-middle">Home</span>
                </router-link>
                <a class="sidebar-link" href="https://certass.co.uk">
                    <i class="align-middle me-2 pi pi-info-circle"></i>
                    <span class="align-middle">Certass InfoSite</span>
                </a>

                <a
                    class="sidebar-link"
                    id="sidebar-applicant-logout"
                    href="/login"
                    @click="logOut"
                >
                    <i class="align-middle me-2 pi pi-sign-out"></i>
                    <span class="align-middle">Sign out</span>
                </a>
            </li>
        </ul>
    </div>
</template>
<script lang="js">
import { mapActions } from 'vuex'

export default {
    name: 'SideBarApplicant',
    components: {

    },
    data: () => {
        return {
        }
    },
    computed: {
        getProfileName() {
            if (this.$store.state.profile?.contact?.firstname && this.$store.state.profile?.contact?.lastname) {
                return  this.$store.state.profile.contact.firstname + ' ' + this.$store.state.profile.contact.lastname
            }

            return ''
        }
    },
    methods: {
        ...mapActions(['logOut'])
    }
}
</script>
<style scoped lang="scss"></style>

// @ts-nocheck

/*
Stores the entities lookup data
*/
import axios from 'axios'

const ChoicesModule = {
    state: {
        records: []
    },
    mutations: {
        setChoices(state, data) {
            state.records.push(data)
        }
    },
    actions: {
        async retrieveEntityChoices({ state, commit }, values) {
            //Do we have this already? If so return that
            let result = state.records.find(record => {
                return (
                    record.entity == values.entity &&
                    record.field == values.field
                )
            })

            if (result) return result

            //ok doesnt exist, get it from Dynamics
            const response = await axios.get(
                `/v1/entities/choices/${values.entity}/${values.field}`
            )

            result = {
                entity: values.entity,
                field: values.field,
                values: response.data.data[0].OptionSet
            }

            commit('setChoices', result)

            return result
        }
    }
}

export default ChoicesModule

<template>
    <span class="required-field"
        ><sup><i class="fas fa-asterisk"></i></sup
    ></span>
</template>

<script lang="js">

export default {
    name: 'FieldRequiredNotification'
}
</script>
<style scoped lang="scss">
.required-field {
    color: rgb(156, 9, 9);
    font-size: 75%;
}
</style>

// @ts-nocheck
import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import AuthModule from '@/store/modules/auth'
import ProfileModule from '@/store/modules/profile'
import AccountsModule from '@/store/modules/accounts'
import EntitiesModule from '@/store/modules/entities'
import ChoicesModule from '@/store/modules/choices'

import router from '@/router'

//The initial state, set on initialisation and on logout
const getDefaultState = () => {
    return {
        auth: { user: null },
        profile: { contact: null },
        accounts: { records: [] },
        entities: { roles: [] },
        choices: { records: [] }
    }
}

//const originalState = getDefaultState()

const store = createStore({
    state() {
        // return originalState
    },
    mutations: {
        logOut(state) {
            Object.assign(state, getDefaultState())
        }
    },
    actions: {
        async logOut({ commit }) {
            await commit('logOut')
            router.push('/login')
        }
    },
    modules: {
        auth: AuthModule,
        profile: ProfileModule,
        accounts: AccountsModule,
        entities: EntitiesModule,
        choices: ChoicesModule
    },
    plugins: [createPersistedState()]
})

export default store

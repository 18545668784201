import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
//import Home from '../views/Home.vue'
//import Navbar from '@/components/Navbar.vue'
import Layout from '@/components/layouts/Layout.vue'
import store from '@/store'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home',
        component: Layout,
        children: [
            {
                path: '/',
                name: 'Home',
                component: () => import('@/views/Home.vue'),
                meta: {
                    guestAccess: true
                }
            },
            {
                path: '/user',
                name: 'HomeUser',
                component: () => import('@/views/AuthHome.vue'),
                meta: { requiresAuth: true, userAccess: true }
            },
            {
                path: '/login',
                name: 'Login',
                component: () => import('@/views/Login.vue'),
                meta: {
                    guestAccess: true
                }
            },
            {
                path: '/register',
                name: 'Register',
                component: () => import('@/views/Register.vue'),
                meta: {
                    guestAccess: true
                }
            },
            {
                path: '/applicant',
                name: 'HomeApplicant',
                component: () => import('@/views/AppHome.vue'),
                meta: {
                    requiresAuth: true,
                    applicantAccess: true
                }
            },
            {
                path: '/account/:id',
                name: 'Account',
                component: () => import('@/views/AccountView.vue'),
                meta: { requiresAuth: true, userAccess: true }
            },
            {
                path: '/account-add',
                name: 'Account Add',
                component: () => import('@/views/AccountAddView.vue'),
                meta: { requiresAuth: true, userAccess: true }
            },
            {
                path: '/:catchAll(.*)',
                component: () => import('@/views/NotFound.vue')
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

//guest access only
router.beforeEach((to, from, next) => {
    if (
        store.getters.isAuthenticated &&
        to.matched.some(record => record.meta.guestAccess)
    ) {
        next(store.getters.defineUserTypeHome)
        return
    }

    next()
})

//Authenticated Users
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters.isAuthenticated) {
            next('/login')
            return
        }
    }

    next()
})

//Applicants
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.applicantAccess)) {
        if (store.getters.getUserType != 'applicant') {
            next(store.getters.defineUserTypeHome)
            return
        }
    }

    next()
})

//Users
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.userAccess)) {
        if (store.getters.getUserType != 'user') {
            next(store.getters.defineUserTypeHome)
            return
        }
    }

    next()
})

export default router

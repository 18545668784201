// @ts-nocheck

/*
Stores the accounts data
*/
import axios from 'axios'

const AccountsModule = {
    state: {
        records: []
    },
    getters: {
        //profileRoles: state => (state.contact ? state.contact.roles : [])
    },
    mutations: {
        addAccount(state, account) {
            state.records.push(account)
        }
    },
    actions: {
        async retrieveAccount({ state, commit }, params) {
            //check if its in the state array already
            const result = state.records.find(record => {
                return record.accountid == params.id
            })

            //if found, return
            if (result) return result

            //if not found, get and add to state
            const response = await axios.get('/v1/profile/account/' + params.id)
            await commit('addAccount', response.data.data[0])
            return response.data.data[0]
        },
        async submitNewAccount({ dispatch }, account) {
            await axios.post('/v1/profile/accounts', account)
            //Now add the Account to the store. Not really needed, but what the hell
            await dispatch('retrieveAccount', { id: account.accountid })
        }
    }
}

export default AccountsModule

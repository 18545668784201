<template>
    <!-- SIDEBAR START-->
    <nav id="sidebar" class="sidebar">
        <a class="sidebar-brand" href="/">
            <img
                alt="Certass Logo"
                width="186"
                height="42"
                src="@/assets/certasslogo.png"
            />
        </a>
        <div v-if="!isAuthenticated">
            <div class="sidebar-content">
                <div class="sidebar-user">
                    <div class="fw-bold" v-if="isAuthenticated">
                        <small class="text-muted">logged in as</small><br />
                        {{ getProfileName }}
                    </div>
                </div>

                <ul class="sidebar-nav">
                    <li class="sidebar-header">
                        What do you want to do?
                    </li>
                    <li class="sidebar-item" v-if="!isAuthenticated">
                        <router-link to="/" class="sidebar-link">
                            <i class="align-middle me-2 pi pi-home"></i>
                            <span class="align-middle">Home</span>
                        </router-link>

                        <router-link to="/login" class="sidebar-link">
                            <i class="align-middle me-2 pi pi-sign-in"></i>
                            <span class="align-middle" id="sidebar-login"
                                >Sign in</span
                            >
                        </router-link>

                        <router-link to="/register" class="sidebar-link">
                            <i class="align-middle me-2 pi pi-user-plus"></i>
                            <span class="align-middle" id="sidebar-register"
                                >Register</span
                            >
                        </router-link>

                        <a class="sidebar-link" href="https://certass.co.uk">
                            <i class="align-middle me-2 pi pi-info-circle"></i>
                            <span class="align-middle">Certass InfoSite</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div v-else>
            <div v-if="getUserType == 'applicant'">
                <side-bar-applicant />
            </div>

            <div v-if="getUserType == 'user'">
                <side-bar-user />
            </div>
        </div>
    </nav>
    <!-- SIDEBAR END-->
</template>

<script lang="js">
import { mapGetters } from 'vuex'
import SideBarApplicant from '@/components/layouts/SideBarApplicant'
import SideBarUser from '@/components/layouts/SideBarUser'

export default {
    name: 'SideBar',
    data: () => {
        return {}
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'getUserType'])
    },
    components: {
        'side-bar-applicant': SideBarApplicant,
        'side-bar-user': SideBarUser,
    },
    methods: {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
a.sidebar-link {
    color: #0c649c;
}
</style>

// @ts-nocheck

/*
Stores the auth data and token returned from Azure B2C
*/
import axios from 'axios'

const AuthModule = {
    state: {
        user: null
    },
    getters: {
        isAuthenticated: state => !!state.user,
        authUser: state => state.user.username,
        getUserType: state => {
            //default is guest
            if (!state.user || !state.user.types) {
                return 'guest'
            }

            //in order of importance
            if (state.user.types.indexOf('members.user') >= 0) {
                return 'user'
            }

            if (state.user.types.indexOf('members.applicant') >= 0) {
                return 'applicant'
            }

            return 'guest'
        },
        defineUserTypeHome: (state, getters) => {
            const user = getters.getUserType
            if (user == 'user') {
                return '/user'
            }

            if (user == 'applicant') {
                return '/applicant'
            }

            return '/'
        }
    },
    mutations: {
        setUser(state, user) {
            state.user = user
        }
    },
    actions: {
        async logIn({ commit }, user) {
            const response = await axios.post('/v1/auth/login', user)
            commit('setUser', {
                id: response.data.data[0].user.id,
                username: response.data.data[0].user.username,
                types: response.data.data[0].user.types
            })
        },
        async register({ commit }, user) {
            const response = await axios.post('/v1/auth/register', user)
            commit('setUser', {
                id: response.data.data[0].user.id,
                username: response.data.data[0].user.username,
                types: response.data.data[0].user.types
            })
        },
        async upgradeAuthUser({ commit }, user) {
            commit('setUser', {
                id: user.id,
                username: user.username,
                types: user.types
            })
        }
    }
}

export default AuthModule
